.framePage {
  padding: 0;
}
.framePage .framePage-body {
  padding-top: 0 ;
}
.searchbox > div .el-input {
  flex: 1;
}
.searchBox {
  padding: 0;
}
.searchBox .el-icon-arrow-down:before {
  content: "\e6df" !important;
}
.searchboxItems {
  display: flex;
  align-items: center;
}
.searchboxItems .itemLabel {
  min-width: 5rem;
  text-align: right;
  font-size: 14px;
  padding: 0 0.5rem;
}
.operationControlCurr > div {
  width: 100%;
  justify-content: space-between;
}
.operationControlCurr .itemLabel {
  min-width: 5rem;
  text-align: right;
  font-size: 14px;
  padding: 0 0.5rem;
}
.listWrap .el-icon-arrow-down {
  font-size: 12px;
}
.listWrap .el-icon-arrow-down:before {
  content: "\e6df" !important;
}
.listWrap .el-tabs {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.listWrap .el-tabs .el-tabs__content {
  flex: 1;
}
.Classitem .el-form-item__content {
  display: flex;
}
.el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
  background-color: transparent;
}
.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
.btnBox {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.exportdialog .el-dialog__title {
  font-size: 16px;
}
.exportdialog .export-box {
  width: 100%;
  margin-bottom: 20px;
}
.exportdialog .export-box h1 {
  padding: 0 5px 10px;
  display: flex;
  align-items: flex-end;
  font-size: 40px;
  border-bottom: 1px dashed #ccc;
}
.exportdialog .export-box h1 span {
  margin-left: 20px;
  font-size: 14px;
}
.exportdialog .export-box > div {
  padding-left: 30px;
}
.exportdialog .export-box .el-button.is-disabled,
.exportdialog .export-box .el-button.is-disabled:focus,
.exportdialog .export-box .el-button.is-disabled:hover {
  color: #fff;
  background: #c3c3c3;
  border: none;
}
.docsDialog .el-form-item {
  margin-bottom: 0.5rem;
}
.studentNum {
  margin: 20px 0 10px;
}
.studentNum span {
  margin: 0 10px;
}
.exportdialog .upload-workers {
  height: 30px;
}
.exportdialog .upload-workers .el-upload {
  height: 30px !important;
  width: 97px;
  border: none !important;
}
.exportdialog .upload-workers .el-upload .el-button {
  padding: 5px 0;
}
 /deep/ ::-webkit-scrollbar-thumb {
  background-color: #eee;
  border-radius: 0px;
}
 /deep/ ::-webkit-scrollbar-track-piece {
  background: #fff;
  border-radius: 0px;
}
 /deep/ .el-pager li {
  background-color: #fff;
  border: 1px solid #d9d9d9;
}
 /deep/ .el-pagination.is-background .el-pager li {
  background-color: #fff;
}
 /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #2878FF;
  border: 1px solid #2878FF;
}
 /deep/ .el-pagination.is-background .btn-next,
 /deep/ .el-pagination.is-background .btn-prev {
  border: 1px solid #d9d9d9;
  background-color: #fff;
}
 /deep/ .el-pager li.active + li {
  border-left: 1px solid #d9d9d9;
}
 /deep/ .el-pagination.is-background .btn-next.disabled,
 /deep/ .el-pagination.is-background .btn-next:disabled,
 /deep/ .el-pagination.is-background /deep/ .btn-prev.disabled,
 /deep/ .el-pagination.is-background .btn-prev:disabled,
 /deep/ .el-pagination.is-background .el-pager li.disabled {
  color: #C0C4CC;
  background-color: #f4f4f5;
  border: 1px solid #d9d9d9;
}
 /deep/ .el-pagination button {
  border: 1px solid #d9d9d9;
}
 /deep/ .el-button--text {
  font-size: 14px!important;
}
 /deep/ .bgc-bv {
  background-color: #2878FF !important;
  color: #fff !important;
  border-color: #2878FF !important;
  border-radius: 0;
}
 /deep/ .el-table th.el-table__cell > .cell {
  font-size: 14px!important;
  font-weight: 400!important;
}
 /deep/ .el-dialog__header {
  background-color: #fff;
}
 /deep/ .el-dialog__header .el-dialog__title {
  color: #333;
}
 /deep/ .el-dialog__header .el-icon-close:before {
  color: #333;
}
 /deep/ .el-range-editor.is-active,
 /deep/ .el-range-editor.is-active:hover,
 /deep/ .el-select .el-input.is-focus .el-input__inner {
  border-color: #2878FF !important;
}
 /deep/ .el-upload {
  height: 35px;
}
 /deep/ .exportdialog .upload-workers {
  height: 35px;
}
