.framePage {
  padding: 0;
}
.framePage .framePage-body {
  padding-top: 0 ;
}
.el-checkbox {
  display: flex;
  white-space: pre-wrap !important;
  text-align: justify;
}
.searchbox > div .el-input {
  flex: 1;
}
.leftData {
  display: flex;
  flex-direction: column;
}
.leftData img {
  width: 300px;
  height: 300px;
}
.leftData span {
  display: flex;
  width: 300px;
}
.searchBox {
  padding: 0;
}
.searchBox .el-icon-arrow-down:before {
  content: "\e6df" !important;
}
.downloadStyle {
  background-color: #f0f5fb;
  padding: 20px 0;
  margin-bottom: 30px;
  overflow: hidden;
}
.docsDialog .el-form-item {
  width: 44%;
  float: left;
  margin-bottom: 0.5rem;
  margin: 10px 3%;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 23px;
  width: 60px;
  display: block;
}
.searchboxItems {
  display: flex;
  align-items: center;
}
.searchboxItems .itemLabel {
  min-width: 5rem;
  text-align: right;
  font-size: 14px;
  padding: 0 0.5rem;
}
.operationControlCurr > div {
  width: 100%;
  justify-content: flex-end;
}
.operationControlCurr .itemLabel {
  min-width: 5rem;
  text-align: right;
  font-size: 14px;
  padding: 0 0.5rem;
}
.el-divider--horizontal {
  margin: 13px 0;
}
.dailyRemind {
  margin-left: 30px;
  margin-top: 10px;
}
.dailyRemind .dailyRemind-1 {
  margin-bottom: 20px;
}
.dailyRemind .dailyRemind-1 p {
  margin-top: 10px;
}
.dailyRemind .dailyRemind-2 p {
  margin-top: 10px;
}
.el-dialogs .el-cascader {
  width: 100%;
}
.upload-btn {
  width: 5rem;
  height: 28px;
}
.upload-btn .el-upload {
  width: 5rem;
  height: 28px !important;
  border: none !important;
}
 /deep/ ::-webkit-scrollbar-thumb {
  background-color: #eee;
  border-radius: 0px;
}
 /deep/ ::-webkit-scrollbar-track-piece {
  background: #fff;
  border-radius: 0px;
}
 /deep/ .el-pager li {
  background-color: #fff;
  border: 1px solid #d9d9d9;
}
 /deep/ .el-pagination.is-background .el-pager li {
  background-color: #fff;
}
 /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #2878FF;
  border: 1px solid #2878FF;
}
 /deep/ .el-pagination.is-background .btn-next,
 /deep/ .el-pagination.is-background .btn-prev {
  border: 1px solid #d9d9d9;
  background-color: #fff;
}
 /deep/ .el-pager li.active + li {
  border-left: 1px solid #d9d9d9;
}
 /deep/ .el-pagination.is-background .btn-next.disabled,
 /deep/ .el-pagination.is-background .btn-next:disabled,
 /deep/ .el-pagination.is-background /deep/ .btn-prev.disabled,
 /deep/ .el-pagination.is-background .btn-prev:disabled,
 /deep/ .el-pagination.is-background .el-pager li.disabled {
  color: #C0C4CC;
  background-color: #f4f4f5;
  border: 1px solid #d9d9d9;
}
 /deep/ .el-pagination button {
  border: 1px solid #d9d9d9;
}
 /deep/ .el-button--text {
  font-size: 14px!important;
}
 /deep/ .bgc-bv {
  background-color: #2878FF !important;
  color: #fff !important;
  border-color: #2878FF !important;
  border-radius: 0;
}
 /deep/ .el-table th.el-table__cell > .cell {
  font-size: 14px!important;
  font-weight: 400!important;
}
 /deep/ .el-dialog__header {
  background-color: #fff;
}
 /deep/ .el-dialog__header .el-dialog__title {
  color: #333;
}
 /deep/ .el-dialog__header .el-icon-close:before {
  color: #333;
}
 /deep/ .el-range-editor.is-active,
 /deep/ .el-range-editor.is-active:hover,
 /deep/ .el-select .el-input.is-focus .el-input__inner {
  border-color: #2878FF !important;
}
