




























































































































































































































































































































































































































































































//人力资源样式重置
/deep/::-webkit-scrollbar-thumb{
background-color: #eee;
border-radius: 0px;
}
/deep/::-webkit-scrollbar-track-piece {
background: #fff;
border-radius: 0px;
}
/deep/.el-pager li{
background-color: #fff;
border: 1px solid #d9d9d9;
}
/deep/.el-pagination.is-background .el-pager li{
background-color: #fff;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active{
background-color: #2878FF;
border: 1px solid #2878FF;
}
/deep/.el-pagination.is-background .btn-next, /deep/.el-pagination.is-background .btn-prev{
border: 1px solid #d9d9d9;
background-color: #fff;
}
/deep/.el-pager li.active+li{
border-left: 1px solid #d9d9d9;
}
/deep/.el-pagination.is-background .btn-next.disabled, /deep/.el-pagination.is-background .btn-next:disabled, /deep/.el-pagination.is-background /deep/.btn-prev.disabled, /deep/.el-pagination.is-background .btn-prev:disabled, /deep/.el-pagination.is-background .el-pager li.disabled {
color: #C0C4CC;
background-color: #f4f4f5;
border: 1px solid #d9d9d9;
}
/deep/.el-pagination button{
border: 1px solid #d9d9d9;
}
/deep/.el-button--text{
//color: #2878FF;
font-size: 14px!important;
}
/deep/.bgc-bv{
background-color: #2878FF !important;
color: #fff !important;
border-color: #2878FF !important;
border-radius: 0;
}
.el-button.is-disabled, .el-button.is-disabled:focus, .el-button.is-disabled:hover{

}
/deep/.el-table th.el-table__cell>.cell{
font-size: 14px!important;
font-weight: 400!important;
}
/deep/.el-dialog__header{
background-color: #fff;
}
/deep/.el-dialog__header .el-dialog__title{
color: #333;
}
/deep/.el-dialog__header .el-icon-close:before{
color: #333;
}
/deep/.el-range-editor.is-active, /deep/.el-range-editor.is-active:hover, /deep/.el-select .el-input.is-focus .el-input__inner{
border-color: #2878FF !important;
}
.CourseTable{
  border-color: #2878FF !important;
}
.CourseTableBox .paperMation{
  border-color: #2878FF !important;
}
